/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets UAListMotivationTypes from api:
 * /v1/ua/motivation_type
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchMotivationTypeUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/motivation_type`,
    models.UAListMotivationTypes
  );
};

/**
 * Gets UAMotivationType from api:
 * /v1/ua/motivation_type/ieg/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getMotivationTypeIegByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/motivation_type/ieg/${iegExecutionID}`,
    models.UAMotivationType
  );
};

/**
 * Post request to path:
 * /v1/ua/motivation/setup-and-start
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMotivationSetupAndStartUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/motivation/setup-and-start`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationIEGKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/motivation/start
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMotivationStartUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/motivation/start`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationIEGKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/motivation/setup
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMotivationSetupUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/motivation/setup`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets UAMotivationList from api:
 * /v1/ua/motivations
 *
 * @param {string} state.
 * @param {any} callback function to call once this function has finished
 */
const fetchMotivationsUtil = (state, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/motivations?state=${state}`,
    models.UAMotivationList
  );
};

/**
 * Post request to path:
 * /v1/ua/motivation/resume
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMotivationResumeUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/motivation/resume`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAResumeMotivationDetailsResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets UAMotivation from api:
 * /v1/ua/motivation/ieg/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getMotivationIegByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/motivation/ieg/${iegExecutionID}`,
    models.UAMotivation
  );
};

/**
 * Post request to path:
 * /v1/ua/mm/motivation/change-ownership
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmMotivationChangeOwnershipUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/motivation/change-ownership`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/application_form
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const createApplicationFormUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/application_form`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAApplicationFormIdentifierResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/hcr/motivation/{motivation_id}/signandsubmit
 *
 * @param {string} motivation_id.
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postHcrMotivationSignandsubmitUtil = (motivation_id, data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/hcr/motivation/${motivation_id}/signandsubmit`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewCESignAndSubmitResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Deletes from api:
 * /v1/ua/mm/motivation/{motivation_id}
 *
 * @param {string} motivation_id.
 * @param {any} callback function to call once this function has finished
 */
const deleteMmMotivationByMotivationIdUtil = (motivation_id, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/motivation/${motivation_id}`;
  RESTService.del(url, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/mm/application_form
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmApplicationFormUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/application_form`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAApplicationFormIdentifierResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets MMSPMVerificationList from api:
 * /v1/ua/mm/verifications
 *
 * @param {string} caseId.
 * @param {string} personId.
 * @param {any} callback function to call once this function has finished
 */
const fetchMmVerificationsUtil = (caseId, personId, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/verifications?caseId=${caseId}&personId=${personId}`,
    models.MMSPMVerificationList
  );
};

/**
 * Post request to path:
 * /v1/dbs/mm/files
 *
 * @param {any} data
 * @param {Object} headers to send
 * @param {any} callback function to call once this function has finished
 */
const postDbsMmFilesUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/dbs/mm/files`;

  RESTService.post(
    url,
    data.file,
    (success, body, header) => {
      callback(success, body, header);
    },
    'application/octet-stream',
    null,
    data.metadataHeaders
  );
};

/**
 * Post request to path:
 * /v1/ua/mm/resetpassword
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmResetpasswordUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/resetpassword`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/mm/hcr_to_cgis
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmHcrToCgisUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/hcr_to_cgis`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewMMNonMagiApplicationFormResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/mm/launch_Intake_Motivation
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmLaunchIntakeMotivationUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/launch_Intake_Motivation`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewMMNonMagiApplicationFormResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/mm/applying_For_NonMagi
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmApplyingForNonMagiUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/applying_For_NonMagi`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/mm/verifications/create_caseworker_task
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmVerificationsCreateCaseworkerTaskUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/verifications/create_caseworker_task`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/mm/is_motivation_script
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmIsMotivationScriptUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/is_motivation_script`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.MMIsMotivationScriptResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets UADraftApplicationList from api:
 * /v1/ua/mm/draft_applications
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchMmDraftApplicationsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/draft_applications`,
    models.UADraftApplicationList
  );
};

/**
 * Gets UASystemPropertyList from api:
 * /v1/ua/mm/system_configurations
 *
 * @param {string} property_name.
 * @param {any} callback function to call once this function has finished
 */
const fetchMmSystemConfigurationsUtil = (property_name, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/system_configurations?property_name=${property_name}`,
    models.UASystemPropertyList
  );
};

/**
 * Gets MMValidateSession from api:
 * /v1/ua/mm/validate_session
 *
 * @param {string} sessionID.
 * @param {any} callback function to call once this function has finished
 */
const fetchMmValidateSessionUtil = (sessionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/validate_session?sessionID=${sessionID}`,
    models.MMValidateSession
  );
};

/**
 * Post request to path:
 * /v1/ua/mm/verifications/link_file
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmVerificationsLinkFileUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/verifications/link_file`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Gets SPMVerification from api:
 * /v1/ua/mm/verifications/{verificationId}
 *
 * @param {string} verificationId.
 * @param {any} callback function to call once this function has finished
 */
const getMmVerificationsByVerificationIdUtil = (verificationId, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/verifications/${verificationId}`,
    models.SPMVerification
  );
};

/**
 * Gets UASubmittedApplicationList from api:
 * /v1/ua/mm/submitted_applications
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchMmSubmittedApplicationsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/submitted_applications`,
    models.UASubmittedApplicationList
  );
};

/**
 * Post request to path:
 * /v1/ua/mm/applications
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmApplicationsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/applications`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAIntakeApplicationIdentifierResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

export {
  fetchMotivationTypeUtil,
  getMotivationTypeIegByIegExecutionIDUtil,
  postMotivationSetupAndStartUtil,
  postMotivationStartUtil,
  postMotivationSetupUtil,
  fetchMotivationsUtil,
  postMotivationResumeUtil,
  getMotivationIegByIegExecutionIDUtil,
  postMmMotivationChangeOwnershipUtil,
  createApplicationFormUtil,
  postHcrMotivationSignandsubmitUtil,
  deleteMmMotivationByMotivationIdUtil,
  postMmApplicationFormUtil,
  fetchMmVerificationsUtil,
  postDbsMmFilesUtil,
  postMmResetpasswordUtil,
  postMmHcrToCgisUtil,
  postMmLaunchIntakeMotivationUtil,
  postMmApplyingForNonMagiUtil,
  postMmVerificationsCreateCaseworkerTaskUtil,
  postMmIsMotivationScriptUtil,
  fetchMmDraftApplicationsUtil,
  fetchMmSystemConfigurationsUtil,
  fetchMmValidateSessionUtil,
  postMmVerificationsLinkFileUtil,
  getMmVerificationsByVerificationIdUtil,
  fetchMmSubmittedApplicationsUtil,
  postMmApplicationsUtil,
};
