/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_MOTIVATION_TYPE,
  GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID,
  POST_MOTIVATION_SETUP_AND_START,
  POST_MOTIVATION_START,
  POST_MOTIVATION_SETUP,
  FETCH_MOTIVATIONS,
  POST_MOTIVATION_RESUME,
  GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID,
  POST_MM_MOTIVATION_CHANGE_OWNERSHIP,
  CREATE_APPLICATION_FORM,
  POST_HCR_MOTIVATION_SIGNANDSUBMIT,
  DELETE_MM_MOTIVATION_BY_MOTIVATION_ID,
  POST_MM_APPLICATION_FORM,
  FETCH_MM_VERIFICATIONS,
  POST_DBS_MM_FILES,
  POST_MM_RESETPASSWORD,
  POST_MM_HCR_TO_CGIS,
  POST_MM_LAUNCH_INTAKE_MOTIVATION,
  POST_MM_APPLYING_FOR_NON_MAGI,
  POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK,
  POST_MM_IS_MOTIVATION_SCRIPT,
  FETCH_MM_DRAFT_APPLICATIONS,
  FETCH_MM_SYSTEM_CONFIGURATIONS,
  FETCH_MM_VALIDATE_SESSION,
  POST_MM_VERIFICATIONS_LINK_FILE,
  GET_MM_VERIFICATIONS_BY_VERIFICATION_ID,
  FETCH_MM_SUBMITTED_APPLICATIONS,
  POST_MM_APPLICATIONS,
  SET_SELECTED_APPLICATION_TYPE,
} from './actionTypes';

import {
  fetchMotivationTypeUtil,
  getMotivationTypeIegByIegExecutionIDUtil,
  postMotivationSetupAndStartUtil,
  postMotivationStartUtil,
  postMotivationSetupUtil,
  fetchMotivationsUtil,
  postMotivationResumeUtil,
  getMotivationIegByIegExecutionIDUtil,
  postMmMotivationChangeOwnershipUtil,
  createApplicationFormUtil,
  postHcrMotivationSignandsubmitUtil,
  deleteMmMotivationByMotivationIdUtil,
  postMmApplicationFormUtil,
  fetchMmVerificationsUtil,
  postDbsMmFilesUtil,
  postMmResetpasswordUtil,
  postMmHcrToCgisUtil,
  postMmLaunchIntakeMotivationUtil,
  postMmApplyingForNonMagiUtil,
  postMmVerificationsCreateCaseworkerTaskUtil,
  postMmIsMotivationScriptUtil,
  fetchMmDraftApplicationsUtil,
  fetchMmSystemConfigurationsUtil,
  fetchMmValidateSessionUtil,
  postMmVerificationsLinkFileUtil,
  getMmVerificationsByVerificationIdUtil,
  fetchMmSubmittedApplicationsUtil,
  postMmApplicationsUtil,
} from './utils';

/**
 * Contains the action creator functions that update the MMModule slice of the store.
 *
 * @namespace MMModuleActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation_type
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static fetchMotivationType = (dispatch, callback) => {
    ReduxUtils.generateAction(dispatch, fetchMotivationTypeUtil, FETCH_MOTIVATION_TYPE, callback);
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMotivationType.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMotivationType = (dispatch, data) => {
    const json = {
      type: FETCH_MOTIVATION_TYPE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMotivationType.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMotivationTypeError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MOTIVATION_TYPE.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation_type/ieg/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static getMotivationTypeIegByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getMotivationTypeIegByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getMotivationTypeIegByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetGetMotivationTypeIegByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getMotivationTypeIegByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetGetMotivationTypeIegByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/setup-and-start
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMotivationSetupAndStart = (dispatch, newUAMotivationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationSetupAndStartUtil.bind(null, newUAMotivationType),
      POST_MOTIVATION_SETUP_AND_START,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationSetupAndStart = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_SETUP_AND_START.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationSetupAndStartError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_SETUP_AND_START.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/start
   *
   * @param {NewUAMotivationKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMotivationStart = (dispatch, newUAMotivationKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationStartUtil.bind(null, newUAMotivationKey),
      POST_MOTIVATION_START,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationStart.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationStart = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_START.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationStart.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationStartError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_START.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/setup
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMotivationSetup = (dispatch, newUAMotivationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationSetupUtil.bind(null, newUAMotivationType),
      POST_MOTIVATION_SETUP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationSetup.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationSetup = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_SETUP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationSetup.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationSetupError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_SETUP.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivations
   *
   * @param {string} state.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static fetchMotivations = (dispatch, state, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMotivationsUtil.bind(null, state),

      FETCH_MOTIVATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMotivations.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMotivations = (dispatch, data) => {
    const json = {
      type: FETCH_MOTIVATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMotivations.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMotivationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MOTIVATIONS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/resume
   *
   * @param {NewUAResumeMotivationKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMotivationResume = (dispatch, newUAResumeMotivationKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationResumeUtil.bind(null, newUAResumeMotivationKey),
      POST_MOTIVATION_RESUME,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationResume.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationResume = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_RESUME.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationResume.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMotivationResumeError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_RESUME.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/ieg/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static getMotivationIegByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getMotivationIegByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getMotivationIegByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetGetMotivationIegByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getMotivationIegByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetGetMotivationIegByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/motivation/change-ownership
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmMotivationChangeOwnership = (dispatch, newUAMotivationIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmMotivationChangeOwnershipUtil.bind(null, newUAMotivationIEGKey),
      POST_MM_MOTIVATION_CHANGE_OWNERSHIP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmMotivationChangeOwnership.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmMotivationChangeOwnership = (dispatch, data) => {
    const json = {
      type: POST_MM_MOTIVATION_CHANGE_OWNERSHIP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmMotivationChangeOwnership.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmMotivationChangeOwnershipError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_MOTIVATION_CHANGE_OWNERSHIP.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/application_form
   *
   * @param {NewUAOnlineApplicationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static createApplicationForm = (dispatch, newUAOnlineApplicationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      createApplicationFormUtil.bind(null, newUAOnlineApplicationType),
      CREATE_APPLICATION_FORM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to createApplicationForm.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetCreateApplicationFormError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, CREATE_APPLICATION_FORM.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/hcr/motivation/{motivation_id}/signandsubmit
   *
   * @param {string} motivation_id.
   * @param {NewCESignAndSubmit} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postHcrMotivationSignandsubmit = (
    dispatch,
    motivation_id,
    newCESignAndSubmit,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postHcrMotivationSignandsubmitUtil.bind(null, motivation_id, newCESignAndSubmit),
      POST_HCR_MOTIVATION_SIGNANDSUBMIT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postHcrMotivationSignandsubmit.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostHcrMotivationSignandsubmit = (dispatch, data) => {
    const json = {
      type: POST_HCR_MOTIVATION_SIGNANDSUBMIT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postHcrMotivationSignandsubmit.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostHcrMotivationSignandsubmitError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_HCR_MOTIVATION_SIGNANDSUBMIT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/motivation/{motivation_id}
   *
   * @param {string} motivation_id.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static deleteMmMotivationByMotivationId = (dispatch, motivation_id, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      deleteMmMotivationByMotivationIdUtil.bind(null, motivation_id),
      DELETE_MM_MOTIVATION_BY_MOTIVATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to deleteMmMotivationByMotivationId.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetDeleteMmMotivationByMotivationId = (dispatch, data) => {
    const json = {
      type: DELETE_MM_MOTIVATION_BY_MOTIVATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to deleteMmMotivationByMotivationId.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetDeleteMmMotivationByMotivationIdError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, DELETE_MM_MOTIVATION_BY_MOTIVATION_ID.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/application_form
   *
   * @param {NewUAOnlineApplicationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmApplicationForm = (dispatch, newUAOnlineApplicationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmApplicationFormUtil.bind(null, newUAOnlineApplicationType),
      POST_MM_APPLICATION_FORM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmApplicationForm.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmApplicationForm = (dispatch, data) => {
    const json = {
      type: POST_MM_APPLICATION_FORM.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmApplicationForm.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmApplicationFormError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_APPLICATION_FORM.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/verifications
   *
   * @param {string} caseId.
   * @param {string} personId.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static fetchMmVerifications = (dispatch, caseId, personId, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmVerificationsUtil.bind(null, caseId, personId),

      FETCH_MM_VERIFICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmVerifications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmVerifications = (dispatch, data) => {
    const json = {
      type: FETCH_MM_VERIFICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmVerifications.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmVerificationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_VERIFICATIONS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/dbs/mm/files
   *
   * @param {NewSPMFileUploadContents} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postDbsMmFiles = (dispatch, newSPMFileUploadContents, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postDbsMmFilesUtil.bind(null, newSPMFileUploadContents),
      POST_DBS_MM_FILES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postDbsMmFiles.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostDbsMmFiles = (dispatch, data) => {
    const json = {
      type: POST_DBS_MM_FILES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postDbsMmFiles.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostDbsMmFilesError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_DBS_MM_FILES.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/resetpassword
   *
   * @param {NewMMResetPasswordDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmResetpassword = (dispatch, newMMResetPasswordDetails, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmResetpasswordUtil.bind(null, newMMResetPasswordDetails),
      POST_MM_RESETPASSWORD,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmResetpassword.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmResetpassword = (dispatch, data) => {
    const json = {
      type: POST_MM_RESETPASSWORD.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmResetpassword.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmResetpasswordError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_RESETPASSWORD.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/hcr_to_cgis
   *
   * @param {NewUAApplicationFormIdentifier} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmHcrToCgis = (dispatch, newUAApplicationFormIdentifier, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmHcrToCgisUtil.bind(null, newUAApplicationFormIdentifier),
      POST_MM_HCR_TO_CGIS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmHcrToCgis.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmHcrToCgis = (dispatch, data) => {
    const json = {
      type: POST_MM_HCR_TO_CGIS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmHcrToCgis.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmHcrToCgisError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_HCR_TO_CGIS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/launch_Intake_Motivation
   *
   * @param {NewMMPopulateNonMagi} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmLaunchIntakeMotivation = (dispatch, newMMPopulateNonMagi, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmLaunchIntakeMotivationUtil.bind(null, newMMPopulateNonMagi),
      POST_MM_LAUNCH_INTAKE_MOTIVATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmLaunchIntakeMotivation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmLaunchIntakeMotivation = (dispatch, data) => {
    const json = {
      type: POST_MM_LAUNCH_INTAKE_MOTIVATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmLaunchIntakeMotivation.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmLaunchIntakeMotivationError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_LAUNCH_INTAKE_MOTIVATION.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/applying_For_NonMagi
   *
   * @param {NewUAApplicationFormId} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmApplyingForNonMagi = (dispatch, newUAApplicationFormId, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmApplyingForNonMagiUtil.bind(null, newUAApplicationFormId),
      POST_MM_APPLYING_FOR_NON_MAGI,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmApplyingForNonMagi.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmApplyingForNonMagi = (dispatch, data) => {
    const json = {
      type: POST_MM_APPLYING_FOR_NON_MAGI.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmApplyingForNonMagi.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmApplyingForNonMagiError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_APPLYING_FOR_NON_MAGI.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/verifications/create_caseworker_task
   *
   * @param {NewCaseParticipantEvdUploadKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmVerificationsCreateCaseworkerTask = (
    dispatch,
    newCaseParticipantEvdUploadKey,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmVerificationsCreateCaseworkerTaskUtil.bind(null, newCaseParticipantEvdUploadKey),
      POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmVerificationsCreateCaseworkerTask.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmVerificationsCreateCaseworkerTask = (dispatch, data) => {
    const json = {
      type: POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmVerificationsCreateCaseworkerTask.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmVerificationsCreateCaseworkerTaskError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/is_motivation_script
   *
   * @param {NewUAApplicationFormIdentifier} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmIsMotivationScript = (dispatch, newUAApplicationFormIdentifier, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmIsMotivationScriptUtil.bind(null, newUAApplicationFormIdentifier),
      POST_MM_IS_MOTIVATION_SCRIPT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmIsMotivationScript.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmIsMotivationScript = (dispatch, data) => {
    const json = {
      type: POST_MM_IS_MOTIVATION_SCRIPT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmIsMotivationScript.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmIsMotivationScriptError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_IS_MOTIVATION_SCRIPT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/draft_applications
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static fetchMmDraftApplications = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmDraftApplicationsUtil,
      FETCH_MM_DRAFT_APPLICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmDraftApplications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmDraftApplications = (dispatch, data) => {
    const json = {
      type: FETCH_MM_DRAFT_APPLICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmDraftApplications.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmDraftApplicationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_DRAFT_APPLICATIONS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/system_configurations
   *
   * @param {string} property_name.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static fetchMmSystemConfigurations = (dispatch, property_name, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmSystemConfigurationsUtil.bind(null, property_name),

      FETCH_MM_SYSTEM_CONFIGURATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmSystemConfigurations.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmSystemConfigurations = (dispatch, data) => {
    const json = {
      type: FETCH_MM_SYSTEM_CONFIGURATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmSystemConfigurations.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmSystemConfigurationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_SYSTEM_CONFIGURATIONS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/validate_session
   *
   * @param {string} sessionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static fetchMmValidateSession = (dispatch, sessionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmValidateSessionUtil.bind(null, sessionID),

      FETCH_MM_VALIDATE_SESSION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmValidateSession.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmValidateSession = (dispatch, data) => {
    const json = {
      type: FETCH_MM_VALIDATE_SESSION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmValidateSession.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmValidateSessionError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_VALIDATE_SESSION.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/verifications/link_file
   *
   * @param {NewSPMVerificationFileLinkCreation} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmVerificationsLinkFile = (dispatch, newSPMVerificationFileLinkCreation, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmVerificationsLinkFileUtil.bind(null, newSPMVerificationFileLinkCreation),
      POST_MM_VERIFICATIONS_LINK_FILE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmVerificationsLinkFile.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmVerificationsLinkFile = (dispatch, data) => {
    const json = {
      type: POST_MM_VERIFICATIONS_LINK_FILE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmVerificationsLinkFile.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmVerificationsLinkFileError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_VERIFICATIONS_LINK_FILE.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/verifications/{verificationId}
   *
   * @param {string} verificationId.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static getMmVerificationsByVerificationId = (dispatch, verificationId, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getMmVerificationsByVerificationIdUtil.bind(null, verificationId),

      GET_MM_VERIFICATIONS_BY_VERIFICATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getMmVerificationsByVerificationId.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetGetMmVerificationsByVerificationId = (dispatch, data) => {
    const json = {
      type: GET_MM_VERIFICATIONS_BY_VERIFICATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getMmVerificationsByVerificationId.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetGetMmVerificationsByVerificationIdError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, GET_MM_VERIFICATIONS_BY_VERIFICATION_ID.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/submitted_applications
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static fetchMmSubmittedApplications = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmSubmittedApplicationsUtil,
      FETCH_MM_SUBMITTED_APPLICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmSubmittedApplications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmSubmittedApplications = (dispatch, data) => {
    const json = {
      type: FETCH_MM_SUBMITTED_APPLICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmSubmittedApplications.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetFetchMmSubmittedApplicationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_SUBMITTED_APPLICATIONS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/applications
   *
   * @param {NewMMCESignAndSubmit} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMModuleActions
   */
  static postMmApplications = (dispatch, newMMCESignAndSubmit, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmApplicationsUtil.bind(null, newMMCESignAndSubmit),
      POST_MM_APPLICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmApplications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmApplications = (dispatch, data) => {
    const json = {
      type: POST_MM_APPLICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmApplications.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMModuleActions
   */
  static resetPostMmApplicationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_APPLICATIONS.failureAction);
  };

  /**
   * Dispatches the data to the store.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMModuleActions
   */
  static setSelectedApplicationType = (dispatch, data) => {
    const json = {
      type: SET_SELECTED_APPLICATION_TYPE,
      payload: data || {},
    };
    dispatch(json);
  };
}
